import axios, { ResponseType } from 'axios'
import { useCallback, useState } from 'react'
import { config } from '../config/config'

export interface IRequest {
    url: string
    method?: string
    params?: object
    headers?: object
    body?: object
    responseType?: ResponseType
}

export interface APIResult {
    isLoading: boolean
    error?: string
    statusCode: number
    result: any
}

export const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [status, setStatus] = useState<number>(200)

    const sendRequest = useCallback(
        async (requestConfig: IRequest) => {
            setIsLoading(true)
            setError(null)

            const { data, status, headers, statusText } = await axios(
                `${config.API_URL}/api/v1/${requestConfig.url}`,
                {
                    method: requestConfig.method
                        ? requestConfig.method
                        : 'GET',
                    headers: requestConfig.headers
                        ? { ...authHeader(), ...requestConfig.headers }
                        : { ...authHeader() },
                    data: requestConfig.body
                        ? JSON.stringify(requestConfig.body)
                        : null,
                    params: requestConfig.params
                        ? requestConfig.params
                        : {},
                    responseType: requestConfig.responseType,
                },
            ).catch(err => {
                setError(err.message || 'Something went wrong!')
                throw new Error(err.message || 'Something went wrong!')
            })

            setStatus(status)
            setIsLoading(false)
            return data
        },
        [],
    )

    const sendRequestAndProcessData = useCallback(
        async (requestConfig: IRequest, applyData: Function) => {
            setIsLoading(true)
            setError(null)

            axios.defaults.withCredentials = true
            const { data, status, headers, statusText } = await axios(
                `${config.API_URL}/api/v1/${requestConfig.url}`,
                {
                    method: requestConfig.method
                        ? requestConfig.method
                        : 'GET',
                    headers: requestConfig.headers
                        ? { ...authHeader(), ...requestConfig.headers }
                        : { ...authHeader() },
                    data: requestConfig.body
                        ? JSON.stringify(requestConfig.body)
                        : null,
                    params: requestConfig.params
                        ? requestConfig.params
                        : {},
                    responseType: requestConfig.responseType,
                },
            ).catch(err => {
                setError(err.message || 'Something went wrong!')
                throw new Error(err.message || 'Something went wrong!')
            })

            applyData(data, headers)
            setIsLoading(false)
        }, [],
    )

    const sendDownloadResourceRequest = useCallback(
        async (requestConfig: IRequest) => {
            sendRequestAndProcessData(
                { ...requestConfig, responseType: 'blob' },
                downloadResource,
            )
        },
        [sendRequestAndProcessData],
    )

    const downloadResource = (file: any, headers: any) => {
        const contentDispositionHeader = headers['content-disposition']
        const type = headers['content-type']
        const fileName = contentDispositionHeader.split('filename=')[1]

        const blob = new Blob([file], {
            type: type,
        })

        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName

        document.body.appendChild(link)

        link.click()

        window.URL.revokeObjectURL(url)
        link.parentNode!.removeChild(link)
    }

    function authHeader() {
        const userToken = localStorage.getItem('user-token')

        if (userToken) {
            return { Authorization: 'Bearer ' + userToken }
        } else {
            return { Authorization: '' }
        }
    }

    return {
        isLoading,
        error,
        status,
        sendRequest,
        sendRequestAndProcessData,
        sendDownloadResourceRequest,
    }
}
